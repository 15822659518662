<template>
  <div>
    <b-modal id="sw-help-modal" size="xl" ref="sw-help-modal">
      <p v-for="t in swHelp.paragraphs">{{ t }}</p>
      <table class="table mb-0 table-center"
        style="width:100%;background-color: #1f2d3d !important; color: #91acde !important;">
        <thead>
          <tr>
            <th v-for="title in swHelp.tableHeader">{{ title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in swHelp.table">
            <td v-for="cell in row">{{ cell }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <b-modal id="chunk-window-modal" ref="chunk-window-modal">
      <p v-for="t in chunkWindow.paragraphs">{{ t }}</p>
    </b-modal>


    <div class="container">
      <div class="row justify-content-center align-content-center">
        <div class="col-8 align-content-center justify-content-center">
          <h1>КластерБластер™</h1>
          <p>Извлекает основные темы из массива текстов</p>
        </div>
      </div>

      <div class="row justify-content-lg-center">
        <div class="col-12">
          <div class="row" style="width:100%;margin:10px;padding:10px">
            <div class="col-5">
              <b-button @click="switchMode()" class="btn btn-outline-primary">Переключить
                на загрузку {{ uploadModesIsDrop ? 'из S3' : 'из браузера' }}</b-button>
            </div>
            <div class="col-7">
              <div v-if="uploadModesIsDrop">
                <form enctype="multipart/form-data" novalidate>
                  <div class="dropbox">
                    <input type="file" name="file1" :disabled="isSaving()" @change="filesChange($event.target.files)"
                      class="input-file" />
                    <p v-if="isInitial()">Файл не загружен</p>
                    <p v-if="isSaving()">Грузим файл...</p>
                    <p v-if="isSuccess()">Файл получили!</p>
                    <p v-if="isFailed()">
                      Что-то пошло не так...<br />
                      <span v-if="this.currentError != ''">
                        {{ this.currentError }}</span>
                    </p>
                  </div>
                </form>
              </div>
              <div v-else>
                <div class="row">
                  <div style="width:70%;" class="col">
                    <select name="s3Selector" id="s3Selector" class="form-control"
                      placeholder="Выбери из списка файлов на S3" v-model="S3FileName">
                      <option v-for="(opt) in s3Files">{{ " " + opt }} </option>
                    </select>

                  </div>
                  <div style="width:30%;" class="col">
                    <b-button @click="filesChangeS3(S3FileName)" class="btn btn-outline-primary"
                      style="width:100%;">Выбрать</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isSuccess()" style="width:100%">
          <b-button v-b-toggle="'collapse-2'">Параметры</b-button>
          <b-collapse visible id="collapse-2">
            <file-proc-input id="custom_name" inputKind="text" v-model="input_api.custom_name" label="Название отчета"
              placeholder="например, отчет по физлицам" />
            <file-proc-input id="clustering_kind" inputKind="radiobuttons" v-model="input_api.clustering_kind"
              label="Тип кластеризации (обязательное поле)" :options="clustOptions" />

            <div v-if="input_api.clustering_kind == 'hier'">
              <file-proc-input class=" offset-sm-4" inputKind="select" id="hier_method" v-model="clustParams.hier.method"
                label="Метод связи кластеров" :options="hierParamsOptions.method" />
              <file-proc-input class="offset-sm-4" inputKind="select" id="hier_metric" v-model="clustParams.hier.metric"
                label="Метрика расстояния" :options="hierParamsOptions.metric" />
            </div>
            <div v-else-if="input_api.clustering_kind == 'hier_multi'">
              <file-proc-input class=" offset-sm-4" inputKind="select" id="hier_method"
                v-model="clustParams.hier_multi.method" label="Метод связи кластеров"
                :options="hierParamsOptions.method" />
              <file-proc-input class=" offset-sm-4" inputKind="select" id="hier_metric"
                v-model="clustParams.hier_multi.metric" label="Метрика расстояния" :options="hierParamsOptions.metric" />
            </div>
            <div v-else-if="input_api.clustering_kind == 'hdbscan'">
              <file-proc-input class=" offset-sm-4" inputKind="text" id="hdbscan_alpha"
                v-model="clustParams.hdbscan.alpha" label="Alpha" />
              <file-proc-input class=" offset-sm-4" inputKind="text" id="hdbscan_min_samples"
                v-model="clustParams.hdbscan.min_samples" label="Min Samples" />
            </div>
            <div v-else-if="input_api.clustering_kind == 'kmeans'">
              <file-proc-input class=" offset-sm-4" inputKind="text" id="kmeans_min_n_clusters"
                v-model="clustParams.kmeans.min_n_clusters" label="Минимальное кол-во кластеров" />
              <file-proc-input class=" offset-sm-4" inputKind="text" id="kmeans_max_n_clusters"
                v-model="clustParams.kmeans.max_n_clusters" label="Максимальное кол-во кластеров" />
            </div>
            <div v-else></div>
            <file-proc-input id="chunk" inputKind="radiobuttons" v-model="input_api.chunk.enabled"
              label="Кластеризация по частям" :options="chunkOptions" />
            <div v-if="input_api.chunk.enabled == true">
              <file-proc-input class=" offset-sm-4" inputKind="radiobuttons" id="chunk_mode"
                v-model="input_api.chunk.chunk_mode" label="Тип чанкинга" :options="chunkModeOptions" />

              <file-proc-input class=" offset-sm-4" inputKind="text" id="n_chunks_to_merge"
                v-model="input_api.chunk.n_chunks_to_merge" label="Кол-во чанков" />
              <file-proc-input v-if="input_api.chunk.chunk_mode != 'window'" inputKind="text" class=" offset-sm-4"
                id="chunk_optimal_rows" v-model="input_api.chunk.optimal_rows" label="Макс. кол-во строк в одном чанке" />
              <file-proc-input v-if="input_api.chunk.chunk_mode == 'random'" inputKind="text" class=" offset-sm-4"
                id="chunk_repeat" v-model="input_api.chunk.repeat" label="Количество перекрытий" />
              <file-proc-input v-if="input_api.chunk.chunk_mode == 'window'" inputKind="text" class=" offset-sm-4"
                id="chunk_window" v-model="input_api.chunk.window" label="Размер окна" enableHelp="true"
                @toggleModalHelp="toggleModal('chunk-window-modal')"></file-proc-input>

            </div>
            <file-proc-input id="ugc" inputKind="radiobuttons" v-model="input_api.ugc" label="UGC"
              :options="ugcOptions" />
            <file-proc-input id="vectorizer_kind" inputKind="radiobuttons" v-model="input_api.vectorizer_kind"
              label="Тип векторизации (обязательное поле)" :options="vectOptions" />
            <file-proc-input id="text_field" inputKind="select" v-model="input_api.text_field"
              label="Название поля с текстом (обязательное поле)" placeholder="например, Текст"
              :options="makeOptMap(columns)" />
            <file-proc-input id="audience_count_field" inputKind="select" v-model="input_api.audience_count_field"
              label="Название поля с охватом" placeholder="например, audience_count" :options="makeOptMap(columns)" />
            <file-proc-input id="source_name_field" inputKind="select" v-model="input_api.source_name_field"
              label="Название поля с площадкой" placeholder="например, source_name" :options="makeOptMap(columns)" />
            <file-proc-input id="published_field" inputKind="select" v-model="input_api.published_field"
              :label="'Название поля с временем' + ((input_api.chunk.enabled === 'true') && (input_api.chunk.chunk_mode != 'random') ? ' (обязательное поле)' : '')"
              placeholder="например, published" :options="makeOptMap(columns)" />
            <file-proc-input id="id_field" inputKind="select" v-model="input_api.id_field"
              label="Название поля с индексом" placeholder="например, id" :options="makeOptMap(columns)" />
            <file-proc-input id="sw-field" inputKind="text" v-model="raw_stopwords" label="Стоп-слова" enableHelp="true"
              placeholder="мама||мыла||раму" @toggleModalHelp="toggleModal('sw-help-modal')" />

          </b-collapse>
        </div>


        <div style="width:100%">

          <a id="div-with-loading" class="btn vs-con-loading__container" :class="[(canSend) ? 'btn-info' : 'btn-danger']"
            @click="sendData" :style="[filesLoaded && canSend ? {} : { 'pointer-events': 'none' }]"
            style="width:100%;margin:10px;padding:10px">
            Отправить данные на расчет <br />
            <small v-if="!canSend">Не заполнены обязательные поля</small>
          </a>
        </div>
        <div>
          <b-button v-b-toggle="'collapse-3'" class="btn btn-outline-primary"
            style="width:100%;margin:10px;padding:10px">Результаты</b-button>
        </div>
        <div style="width:100%;margin:10px;padding:10px">
          <b-collapse visible id="collapse-3">
            <div v-if="requestHistory.length == 0">
              <a style="text-align:center;">Доступных отчетов нет</a>
            </div>
            <div v-else>
              <table class="table mb-0 table-center"
                style="width:100%;background-color: #1f2d3d !important; color: #91acde !important;">
                <thead class="bg-light">
                  <tr>
                    <th scope="col" style="width:5%; text-align:center;">Инфо</th>
                    <th scope="col" style="width:20%; text-align:center;">Название датасета</th>
                    <th scope="col" style="width:17%; text-align:center;">Начало</th>
                    <th scope="col" style="width:13%; text-align:center;">Длительность</th>
                    <th scope="col" style="width:35%; text-align:center;">Статус</th>
                    <th scope="col" style="width:5%; text-align:center;"></th>
                    <th scope="col" style="width:5%; text-align:center;">Отменить</th>
                  </tr>

                </thead>
                <tbody>
                  <tr v-for="request in requestHistory"
                    style="background-color: #1f2d3d !important; color: #91acde !important;">
                    <td style="text-align:center;" :id=popoverTarget(request.request_name)>
                      <info-icon></info-icon>
                    </td>

                    <td style=" text-align:center;">
                      <p class="text-secondary">{{ request.task_info.custom_name }}</p>
                    </td>
                    <td style=" text-align:center;">
                      <p class="text-secondary">{{ request.status_info.time_start }}</p>
                    </td>
                    <td style=" text-align:center;">
                      <p class="text-secondary">{{ request.status_info.time_elapsed }}</p>
                    </td>
                    <td style=" text-align:center;">
                      <b-progress :max="request.status_info.status == 'WORKING' ? request.status_info.stage_total : 1"
                        height="2rem">
                        <b-progress-bar
                          :value="request.status_info.status == 'WORKING' ? request.status_info.stage_ix : 1"
                          :variant=getBarColor(request.status_info.status) :striped="striped" :animated="striped">
                          <span v-if="request.status_info.status != 'WORKING'">{{ request.status_info.status }}</span>
                          <span v-if="request.status_info.status == 'WORKING'">{{ request.status_info.stage_name
                          }}</span>
                        </b-progress-bar>
                      </b-progress>
                    </td>
                    <td style="text-align:center;">
                      <a @click="downloadFile(request.request_name)">
                        <download-icon style="cursor: pointer;"></download-icon>
                      </a>
                    </td>
                    <td><a @click="cancelRequest(request.request_name)"><x-icon style="cursor: pointer;"></x-icon></a>
                    </td>
                    <b-popover :target=popoverTarget(request.request_name) triggers="hover" placement="leftbottom">
                      <ul>
                        <li>Текстовое поле: {{ request.task_info.text_field }}</li>
                        <li v-if="request.task_info.fields_names.audience_count != ''">Поле с аудиторией: {{
                          request.task_info.fields_names.audience_count }}</li>
                        <li v-if="request.task_info.fields_names.source_name != ''">Поле с источником: {{
                          request.task_info.fields_names.source_name }}</li>
                        <li v-if="request.task_info.fields_names.published != ''">Поле с датой: {{
                          request.task_info.fields_names.published }}</li>
                        <li v-if="request.task_info.fields_names.id != ''">Поле с индексом: {{
                          request.task_info.fields_names.id }}</li>
                        <li v-if="request.task_info.stopwords != []">Стоп-слова: {{
                          request.task_info.stopwords }}</li>
                        <li>Кластеризация: {{ request.task_info.clustering_kind }}</li>
                        <li>Векторизация: {{ request.task_info.vectorizer_kind }}</li>
                        <li>По частям: {{ request.task_info.chunk }}</li>
                        <li>UGC: {{ request.task_info.ugc }}</li>
                        <li>Имя запроса (для работы с ошибками): {{ request.task_info.task_name }}</li>
                      </ul>
                    </b-popover>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon, InfoIcon, XIcon } from "vue-feather-icons";
import FileProcInput from "./fileProcInput.vue";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  components: {
    MessageCircleIcon,
    BookIcon,
    InfoIcon,
    XIcon,
    DownloadIcon,
    FileProcInput,
  },
  name: "Kruchevo",
  data() {
    return {
      columns: [],
      striped: true,
      true_hostname: this.$hostname_ba,
      input_api: {
        text_field: "",
        custom_name: "",
        audience_count_field: "",
        source_name_field: "",
        published_field: "",
        id_field: "",
        clustering_kind: "",
        vectorizer_kind: "e5li",
        ugc: "none",
        stopwords: "",
        clustering_params: {},
        userId: store.state.user_id,
        chunk: {
          n_chunks_to_merge: 3,
          optimal_rows: 5000,
          chunk_mode: 'random',
          repeat: 3,
          window: '8h',
          enabled: false,
        },
      },
      chunk_selected: false,
      currentStatus: STATUS_INITIAL,
      currentError: "",
      s3Files: [],
      S3FileName: '',
      tableShow: false,
      showStatus: false,
      lastEdit: "",
      uploadModesIsDrop: true,
      status: {
        rows_in: 0,
        rows_out: 0,
      },
      calcInProgress: false,
      excelResults: [],
      refreshTime: 1,

      clustOptions: [
        { text: 'HDBSCAN', value: 'hdbscan' },
        { text: 'KMeans', value: 'kmeans' },
        { text: 'Иерархическая', value: 'hier' },
        { text: 'Мульти-иерархическая', value: 'hier_multi' },
      ],
      vectOptions: [
        //{ text: 'LaBSE', value: 'bm' },
        //{ text: 'E5-Large', value: 'e5' },
        { text: 'E5-Large-instruct', value: 'e5li' },
        // { text: 'M3-VK', value: 'm3vk' },
        //{ text: 'M3-Sber', value: 'm3ft' },
        //{ text: 'Лоскутик', value: 'patch' },
      ],
      chunkOptions: [
        { text: 'Отключено', value: false, help: 'обработка быстрее, ошибки при большом датасете' },
        { text: 'Включено', value: true, help: 'обработка медленнее, нет ограничения на размер датасета' },
      ],
      ugcOptions: [
        { text: 'Отключено', value: 'none' },
        { text: 'Разметка', value: 'mark', 'help': 'добавляет метки UGC' },
        { text: 'Фильтр', value: 'filter', 'help': 'добавляет метки UGC и убирает не-UGC на кластеризации' },
      ],
      chunkModeOptions: [
        { text: 'Random', value: 'random', help: 'самплинг всего датасета' },
        { text: 'Sorted', value: 'sorted', help: 'равные по размеру семплы' },
        { text: 'Window', value: 'window', help: 'равные промежутки времени' },
      ],
      raw_stopwords: "",
      clustParams: {
        hier: {
          metric: 'cosine',
          method: 'weighted'
        },
        hier_multi: {
          metric: 'cosine',
          method: 'weighted'
        },
        hdbscan: {
          alpha: 0.4,
          min_samples: 1
        },
        kmeans: {
          min_n_clusters: 5,
          max_n_clusters: 30
        }
      },
      hierParamsOptions: {
        metric: [
          { text: 'Косинусные', value: 'cosine' },
          { text: 'Евклидовы', value: 'euclidean' }
        ],
        method: [
          { text: 'Взвешенный (исходный)', value: 'weighted' },
          { text: 'Единичный', value: 'single' },
          { text: 'Центроидный', value: 'centroid' },
          { text: 'Медианный', value: 'median' },
          { text: 'Полный', value: 'complete' },
          { text: 'Средний', value: 'average' },
          { text: 'Вард', value: 'ward' },
        ]
      },
      requestHistory: [
      ],
      statusBarData: {
        currentStage: '',
        currentStageId: 0,
        totalStages: 1,
        currentFrac: 0.0,
        timeSinceStageStart: '',
        timeSincePipelineStart: '',
        progbarVariant: 'info'
      },
      barColors: {
        PENDING: 'secondary',
        WORKING: 'info',
        CANCELLED: 'secondary',
        ERROR: 'danger',
        DONE: 'success'
      },
      showProgressData: false,
      chunkWindow: {
        paragraphs: [
          "Здесь можно задать размер окна; кластеризация будет делаться на семпле, который помещается в такое окно.",
          "Окно определяется в юнитах - минутах (m), часах(h), днях(d) или неделях(w).",
          "Например, '2m' - окно в две минуты, '8h' - 8 часов."
        ]
      },
      swHelp: {
        table: [{
          "type": "символ",
          "value": ".",
          "example": ".бер -> 'сбер', 'Збер',",
          "description": "Заменяет собой один любой символ (включая знаки препинания, пробелы и тд)",
        },
        {
          "type": "символ",
          "value": "[ ]",
          "example": "сб[аяоуы]р -> 'сбер', 'сбыр'",
          "description": "Заменяет собой любой символ из перечисленных. Поддерживает массивы: [а-я] - любая маленька буква алфавита, [а-яА-Я] - любая буква алфавита, [0-9 ] - любая цифра и пробел. Для матчинга спецсиволов их нужно экранировать: [0\-9] заматчит '0', '9' или '-'.",
        },
        {
          "type": "символ",
          "value": "\\b",
          "example": "сбер\\b -> 'сбер ', 'сбер)', 'сбер.'",
          "description": "Обозначает границу слова (пробел, начало или конец строки). Граница слова = промежуток между буквой и не-буквой. НЕ матчит символ за границей (в примерах - пробел, скобка и точка соответственно)",
        },
        {
          "type": "символ",
          "value": "\\s",
          "example": "сбер\\s -> 'сбер '",
          "description": "Обозначает пробел и аналогичные символы(включая табы, перенос строки и проч). В отличие от '\b' матчит этот символ",
        },
        {
          "type": "модификатор",
          "value": "?",
          "example": "бр?о -> 'бро', 'бо'",
          "description": "Допускает встречаемость символа 0 или 1 раз",
        },
        {
          "type": "модификатор",
          "value": "+",
          "example": "бр+о -> 'бро', 'бррррро'",
          "description": "Допускает встречаемость символа 1 раз и больше",
        },
        {
          "type": "модификатор",
          "value": "*",
          "example": "бр*о -> 'бо', 'бро', 'брррро'",
          "description": "Допускает встречаемость символа любое количество раз (включая 0)",
        },
        {
          "type": "модификатор",
          "value": "{a}",
          "example": "бр{3}о -> 'бррро'",
          "description": "Допускает встречаемость символа точно 'a' раз",
        },
        {
          "type": "модификатор",
          "value": "{a,b}",
          "example": "бр{1,4}о -> 'бро', 'брррро'",
          "description": "Допускает встречаемость символа от 'a' до 'b' раз",
        },
        ],
        tableHeader: ["Тип",
          "Оператор",
          "Пример",
          "Описание",],
        paragraphs: [
          "Регулярные выражения (регулярки) - это 'шаблоны', позволяющие матчить слова по некоторой спецификации. В КластерБластере регулярки используются для устранения стоп-слов: все куски текста, заматченные предоставленными стоп-словами, будут удалены из текста и не попадут в обработку. Самая базовая регулярка - это само слово: регулярка 'сбербанк' заматчит слово 'сбербанк' в тексте. Если мы хотим использовать спецсимволы в буквальном значении - их надо экранировать (например 'сбербанк.ру' -> 'сбербанк\.ру')",
          "Для тестирования регулярки рекомендую этот сервис - https://regex101.com. В верхнем поле вводим регулярку, в нижнем - текст, который мы хотим заматчить. Весь заматченный текст подсвечивается и описывается справа. NB: в списке слева ('Flavor') следует выбрать 'python' - диалекты регулярок немного меняются от языка к языку.",
          "Полная документация по регуляркам - здесь: https://docs.python.org/3/library/re.html",
          "Мы опишем два основных типа операторов: символ (обозначает некоторый символ или набор символов, которые мы хотим заматчить) и модификатор (меняет поведение замен символов)",
          "В текущей реализации отдельные стоп-слова отделяются друг от друга двумя пайпами (например 'мама||мыла||раму')."
        ]



      },
    };
  },

  computed: {
    filesLoaded: function () {
      return this.currentStatus > 1
    },
    canSend: function () {
      return (this.currentStatus > 1) && (this.input_api.text_field != "") && (this.input_api.clustering_kind != "") && (this.input_api.vectorizer_kind != "") && (((this.input_api.chunk.enabled === 'true') && (this.input_api.chunk.chunk_mode != 'random') ? this.input_api.published_field != "" : true))
    },
  },
  methods: {
    toggleModal(modalName) {
      this.$refs[modalName].toggle()
    },

    // toggleModal('sw-help-modal')
    switchMode() {
      this.uploadModesIsDrop = !this.uploadModesIsDrop
    },
    getBarColor(status) {
      return this.barColors[status]

    },
    prettyClstName(clst_type) {
      let P = this.clustOptions.filter((row) => row.value == clst_type);

      return (P.length > 0) ? P[0].option : '';
    },
    prettyVectName(vect_type) {
      let P = this.vectOptions.filter((row) => row.value == vect_type);

      return (P.length > 0) ? P[0].option : '';
    },

    popoverTarget(id) {
      return `popover-target-${id}`
    },
    makeOptMap(valsList) {
      let objList = Array();
      for (const val in valsList) {
        objList.push({ 'text': valsList[val], 'value': valsList[val] });
      }
      return objList
    },
    tryPolling() {
      let statuses = this.requestHistory.map((x) => x.status_info.status);
      if (('WORKING' in statuses) || ('PENDING' in statuses)) {
        this.pollLastSaved();
      };

    },
    pollLastSaved() {
      console.log('Polling statuses');
      const url = `${this.true_hostname}/kruchevo/checkLastEdited/${store.state.user_id}`;

      this.axios
        .get(url)
        .then((x) => {
          this.requestHistory = x.data.statuses;
      	console.log('Polling statuses: ', this.requestHistory);
        }
        );
    },
    pollS3Files() {
      console.log(this.true_hostname);
      this.axios({
        method: "get",
        url: `${this.true_hostname}/kruchevo/checkFilesS3`,
      }).then((x) => {
        console.log(x.data);
        this.s3Files = x.data.filenames;
      }
      );
    },

    isInitial: function () {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving: function () {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess: function () {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed: function () {
      return this.currentStatus === STATUS_FAILED;
    },
    filesChange(fileList) {
      this.currentStatus = STATUS_SAVING;
      // handle file changes
      var formData = new FormData();

      if (!fileList.length) return;
      // append the files to FormData
      formData.append("file", fileList[0]);
      // save it
      this.save(formData);
    },
    filesChangeS3(filename) {
      console.log(this.S3FileName);
      this.currentStatus = STATUS_SAVING;
      this.uploadS3(filename)
        .then((x) => {
          console.log(x);
          if (x == 200) {
            this.currentStatus = STATUS_SUCCESS;
          } else {
            this.currentStatus = STATUS_FAILED;
            this.currentError = `Error ${x}`;
          }
        })
        .catch((err) => {
          this.currentError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },

    save(formData) {
      // upload data to the server
      this.upload(formData)
        .then((x) => {
          console.log(x);
          if (x == 200) {
            this.currentStatus = STATUS_SUCCESS;
          } else {
            this.currentStatus = STATUS_FAILED;
            this.currentError = `Error ${x}`;
          }
        })
        .catch((err) => {
          this.currentError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },



    async uploadS3(filename) {
      const url = `${this.true_hostname}/kruchevo/uploadfileFromS3`;
      let data = {
        userId: store.state.user_id,
        filename: filename
      }
      console.log(data);
      const ret = await this.axios({
        method: "post",
        url: url,
        data: data,
      })
        .then((x) => x.data);
      this.columns = [''].concat(ret.columns);
      return ret.status;
    },

    async upload(formData) {
      const url = `${this.true_hostname}/kruchevo/uploadfile/${store.state.user_id}`;
      const ret = await this.axios
        .post(url, formData)
        .then((x) => x.data);
      this.columns = [''].concat(ret.columns);
      return ret.status;
    },

    sendData() {
      if (this.input_api.text == '') {
        this.currentError = ''
      }
      this.input_api.stopwords = this.raw_stopwords.split(/\|\|/);

      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.5,
        opacity: 0.1,
      });
      console.log(this.input_api);
      this.calcInProgress = true;
      this.input_api.clustering_params = this.clustParams[this.input_api.clustering_kind];
      this.axios({
        method: "post",
        url: `${this.true_hostname}/kruchevo/process_data`,
        data: this.input_api,
      })
        .then((res) => {
          console.log("successful processing");
          console.log(res.data);
          this.pollLastSaved();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((err) => {
          console.log("unsuccessful processing");
          console.error(err);
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
    },
    cancelRequest(requestName) {

      this.axios({
        method: "post",
        url: `${this.true_hostname}/kruchevo/cancel`,
        data: {
          request_name: requestName,
        },
      })
        .then((res) => {
          this.pollLastSaved();
        })
        .catch((err) => {
          this.pollLastSaved();
        });

    },
    downloadFile(requestName) {
      console.log('download pressed');
      this.axios({
        method: "get",
        url: `${this.true_hostname}/kruchevo/get_custom_name/${requestName}`,
      }).then((res) => {
        let custom_name = res.data;
        //     return res.data;
        //   });

        // console.log(custom_name);

        this.axios({
          method: "post",
          url: `${this.true_hostname}/kruchevo/fetch_results`,
          data: {
            request_name: requestName,
            userId: store.state.user_id,
          },
          responseType: "blob",
        }).then(function (response) {
          console.log(response);
          const blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.download = `${custom_name}.xlsx`
          // aEle.download = "report.xlsx"; // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object
        });
      });

    },
  },
  beforeMount() {
    this.pollLastSaved(true);
    this.pollS3Files();
    console.log(this.s3Files);

  },
  mounted() {
    this.refreshTimer = setInterval(() => {
      let statuses = this.requestHistory.map((x) => x.status_info.status);
      if (statuses.includes('WORKING')  || statuses.includes('PENDING')) {
        this.pollLastSaved();
      };
    }, 30000);

 }
};
</script>


<style scoped></style>
